import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Timeline } from "@/components/ui/timeline";
import { AuroraText } from "@/components/ui/aurora-text";

export function Prometheus() {
  const data = [
    {
      title: "Purpose",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            Project Prometheus is my attempt to create something extraordinary—a digital version of myself. It’s an exercise in seeing how close we can come to building a reflection of who we are. The goal is simple: create a system that feels like you’re talking to me, just through your screen.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://willdam20.wordpress.com/wp-content/uploads/2020/10/cq5dam.web_.1280.1280.jpeg?w=1024"
              alt="Illustration of purpose"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Model and Technology Design",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            At the heart of this project is Hermes, a language model that can already hold a conversation through text. The next step is giving it a voice—literally. I’m exploring two tools for this: Tortoise TTS and Coqui TTS. Both could make Hermes sound more like, well, me.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://6187708.fs1.hubspotusercontent-na1.net/hubfs/6187708/Depositphotos_336298336_XL.jpeg"
              alt="Illustration of technology design"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Training Data",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            To really nail the voice, I need samples of myself speaking. Not just random phrases, but a range that covers different sounds in English—and some variety in emotion too. That way, the system can capture the way I naturally talk and express myself. I have added a training sample below that covers all letters of the english aphabet as well as my own name, we will then use this to benchmark how well the chosen models perform.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://imageio.forbes.com/specials-images/imageserve/65033b85fb3035d47a2250b8/0x0.jpg?format=jpg&height=600&width=1200&fit=bounds"
              alt="Illustration of training data"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
          </div>

          <div className="flex justify-center mt-4">
            <audio controls className="w-full rounded-md">
              <source src="https://storage.googleapis.com/ashes_project_website_artifacts/audio/TrainingInput.wav" type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      ),
    },
    {
      title: "TTS Model Comparisons",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            In doing my research it was really difficult finding the right models, as the TTS (Text To Speech) world is highly lucrative, there aren't many (good) open source models out there. Next up we will compare two of the best at the moment.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://img.freepik.com/free-photo/colorful-duo-tone-background_23-2148569177.jpg"
              alt="Comparison Image"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
          </div>
        </div>
      ),
    },
    {
      title: "TTS Model Comparisons: Coqui TTS",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            Coqui TTS is an established text-to-speech library, offering a wide range of models like Tacotron, VITS, and Glow-TTS. It emphasizes versatility, supporting numerous languages and multi-speaker functionalities, including voice cloning and conversion. Coqui TTS provides tools for training and fine-tuning models, and is well-documented with community support, making it suitable for both research and practical applications. Its strengths are its comprehensive nature, broad language support, and focus on real-world use.
          </p>
          <div className="flex justify-center relative"> {/* Image container with relative positioning */}
            <LazyLoadImage
              src="https://opengraph.githubassets.com/31078a40cfb564f4b0e3a1b7521240647a0c7d4272213a5f3c176b10cb3cf715/coqui-ai/TTS"
              alt="Comparison Image"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
             <div className="absolute top-2 right-2"> {/* Button now inside image container */}
                <a href="https://github.com/coqui-ai/TTS?tab=readme-ov-file" target="_blank" rel="noopener noreferrer">
                  <button className="bg-white text-black p-2 rounded-full border border-neutral-300 hover:shadow-md transition-shadow duration-200 flex items-center justify-center">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg" alt="GitHub" className="w-5 h-5" />
                  </button>
                </a>
              </div>
          </div>
          <div className="flex justify-center mt-4">
            <audio controls className="w-full rounded-md">
              <source src="https://storage.googleapis.com/ashes_project_website_artifacts/audio/XTTSOutput.wav" type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      ),
    },
    {
      title: "TTS Model Comparisons: E2/F5 TTS",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            Coqui TTS is an established text-to-speech library, offering a wide range of models like Tacotron, VITS, and Glow-TTS. It emphasizes versatility, supporting numerous languages and multi-speaker functionalities, including voice cloning and conversion. Coqui TTS provides tools for training and fine-tuning models, and is well-documented with community support, making it suitable for both research and practical applications. Its strengths are its comprehensive nature, broad language support, and focus on real-world use.
          </p>
          <div className="flex justify-center relative"> {/* Image container with relative positioning */}
            <LazyLoadImage
              src="https://cdn-thumbnails.huggingface.co/social-thumbnails/spaces/ThreadAbort/E2-F5-TTS.png"
              alt="Comparison Image"
              width={500}
              height={500}
              className="rounded-lg object-cover h-40 md:h-60 lg:h-80 w-full"
            />
             <div className="absolute top-2 right-2"> {/* Button now inside image container */}
                <a href="https://github.com/SWivid/F5-TTS" target="_blank" rel="noopener noreferrer">
                  <button className="bg-white text-black p-2 rounded-full border border-neutral-300 hover:shadow-md transition-shadow duration-200 flex items-center justify-center">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg" alt="GitHub" className="w-5 h-5" />
                  </button>
                </a>
              </div>
          </div>
          <div className="flex justify-center mt-4">
            <audio controls className="w-full rounded-md">
              <source src="https://storage.googleapis.com/ashes_project_website_artifacts/audio/E2%3AFEOutput.wav" type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      ),
    },
    {
      title: "E2/F5 TTS: The Winner for Realistic Voice Cloning",
      content: (
        <div className="space-y-8">
          <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
            While both Coqui TTS and E2/F5 TTS are impressive, E2/F5 TTS takes the lead when it comes to realistic voice cloning, particularly with its one-shot approach. This model excels at capturing the nuances of a voice from a single reference sample, resulting in a clone that sounds remarkably like the original speaker.  A key advantage is its ability to minimize accent artifacting that can sometimes arise from the underlying model itself in other TTS systems. This leads to a cleaner, more authentic voice clone that truly reflects the target voice.
          </p>
          <div className="flex justify-center">
            <LazyLoadImage
              src="https://static.vecteezy.com/system/resources/previews/011/351/201/non_2x/elegant-golden-scene-diagonal-glowing-with-lighting-effect-sparkle-on-black-background-template-premium-award-design-vector.jpg"
              alt="E2/F5 TTS Winner Image"
              width={500}
              height={500}
              className="rounded-lg object-fit h-40 md:h-60 lg:h-80 w-full"
            />
          </div>
        </div>
      ),
    },
    {
        title: "Digital likeness",
        content: (
          <div className="space-y-8">
            <p className="text-neutral-800 dark:text-neutral-800 text-xs md:text-sm font-normal">
              With a working voice cloning system in place, the next step is to clone my phyiscal likeness; and being able to direct this digital clone.
            </p>
            <div className="flex justify-center">
              <LazyLoadImage
                src="https://artofvfx.com/wp-content/uploads/2020/07/Westworld_S3_ILP_ITW_01B.jpg"
                alt="E2/F5 TTS Winner Image"
                width={500}
                height={500}
                className="rounded-lg object-fit h-40 md:h-60 lg:h-80 w-full"
              />
            </div>
          </div>
        ),
      },
  ];

  return (
    <div>
      <div className="overflow-hidden" style={{ animation: "fadeIn 2s" }}>
        <Timeline data={data} />
      </div>
    </div>
  );
}