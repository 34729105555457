import React from "react";
import ProjectCard from "./ProjectCards";
import dwml from "../../Assets/Projects/DelosBanner.png";
import datascience from "../../Assets/Projects/DSLogo.png";
import analysis from "../../Assets/Projects/DataAnalytics.png";
import Midas from "../../Assets/Projects/MidasCircle.png";
import Orwell from "../../Assets/Projects/ProjectOrwell.png";
import Prometheus from "../../Assets/Projects/Prometheus.png";

function Projects() {
  return (
    <div className="w-full bg-white py-8 min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-8">
        <h1 className="text-4xl font-light text-black text-center mb-10 pt-12">Projects</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:p-12 pb-8">
          <ProjectCard
            imgPath={dwml}
            isBlog={false}
            active={true}
            title="Project Delos"
            description="Project Delos is a fully fledged software development project from A-Z, showcasing the full lifecycle of a software development project, duplicated across six separate web frameworks as backends."
            tags={[
              "Flask",
              "Fastapi",
              "Django",
              "Express",
              "Next.js",
              "SvelteKit",
              "ORM",
              "SQL",
              "Devops",
            ]}
            demoLink="/Delos"
          />
          <ProjectCard
            imgPath={datascience}
            isBlog={false}
            active={true}
            title="Data Science Notebooks"
            description="A collection of my data science-related notebooks tackling challenges from everyday life or topics of interest."
            tags={[
              "Keras",
              "Tensorflow",
              "Jupyter",
              "Seaborn",
              "Pandas",
              "EDA",
              "LSTM",
              "RNN",
            ]}
            demoLink="/DS"
          />
          <ProjectCard
            imgPath={analysis}
            isBlog={false}
            active={true}
            title="Data Engineering Projects"
            description="Data engineering projects to understand the nuts and bolts of handling data at scale for tech leaders."
            tags={["Airflow", "BI", "Data Warehousing", "ETL", "PubSub"]}
            demoLink="/DA"
          />
          <ProjectCard
            imgPath={Orwell}
            isBlog={false}
            active={true}
            title="Project Orwell"
            description="Focus on handling and displaying in-app analytics for making informed product decisions."
            tags={["Analytics", "Streaming", "BI", "Looker Studio", "Big Query"]}
            demoLink="/Orwell"
          />
          <ProjectCard
            imgPath={Midas}
            isBlog={false}
            active={true}
            title="Project Midas"
            description="Monetize your application with various options such as invoicing, subscriptions, and QR payments."
            tags={[
              "Invoicing",
              "EFT",
              "Credit Card",
              "QR Payment",
              "Subscription",
              "IAP",
            ]}
            demoLink="/Midas"
          />
          <ProjectCard
            imgPath={Prometheus}
            isBlog={false}
            active={true}
            title="Project Prometheus"
            description="Creating end-to-end ML systems and exploring the promise and challenges of AI."
            tags={["MLOps", "Devops", "AI", "ML"]}
            demoLink="/Prometheus"
          />
        </div>
      </div>
    </div>
  );
}

export default Projects;