import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card"; // Replace with your shadcn card component path
import { FaArrowRightLong } from "react-icons/fa6";

function ProjectCards(props) {
  const [hover, setHover] = useState(false);

  return (
    <Link to={props.active ? props.demoLink : "#"}>
      <div className="relative">
        <Card
          className="project-card-view relative flex flex-col justify-between h-full shadow-none p-3 md:p-4 lg:p-6 group" // Increased padding on larger screens
          onClick={() => {
            if (props.active) {
              window.analytics.track("Project Opened", {
                project: props.title,
              });
            }
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {/* Image */}
          <CardHeader className="p-0 relative w-full h-40 md:h-48 lg:h-56 xl:h-64 flex justify-center items-center overflow-hidden"> {/* Increased image container height on larger screens */}
            <img
              src={props.imgPath}
              alt="card-img"
              className="rounded-lg object-cover w-full h-full transition-all duration-500 ease-in-out transform md:group-hover:scale-105"
              style={{
                opacity: hover ? 0.5 : 1, // Slight opacity reduction for the image on hover
              }}
            />
          </CardHeader>

          <CardTitle className="text-sm sm:text-base md:text-lg font-light mb-2 text-gray-800 sm:hidden"> {/* Increased title size on medium screens and up for hidden title */}
            {props.title}
          </CardTitle>

          {/* Text content */}
          <CardContent
            className="absolute inset-0 flex flex-col justify-center items-center transition-opacity duration-500 ease-in-out z-10 opacity-100 md:group-hover:opacity-100"
            style={{
              backgroundColor: hover ? "rgba(0, 0, 0, 0.9)" : "transparent", // Darker background for readability on hover
              transition: "background-color 0.5s ease-in-out", // Gradual transition for background color
            }}
          >
            <CardTitle className="text-sm sm:text-base md:text-lg lg:text-xl font-semibold mb-2 text-transparent transition-all duration-500 ease-in-out md:group-hover:text-white"> {/* Increased title size responsively */}
              {props.title}
            </CardTitle>
            <div
              className="text-xs sm:text-sm md:text-base lg:text-md font-medium text-transparent transition-all duration-500 ease-in-out md:group-hover:text-white" // Increased description size responsively
              style={{
                padding: "0 10px",
                textAlign: "center",
                lineHeight: "1.4", // Improve readability
              }}
            >
              {props.description}
            </div>

            {/* Learn More Section */}
            <div
              className="absolute bottom-10 right-10 flex items-center mt-4 text-sm sm:text-base md:text-md lg:text-lg font-medium text-transparent transition-all duration-800 ease-in-out md:group-hover:text-white" // Increased "See More" text size responsively
              style={{
                padding: "0 10px",
                textAlign: "center",
                lineHeight: "1.4", // Improve readability
              }}
            >
              <span className="mr-1 text-sm sm:text-base md:text-md lg:text-lg font-light"> {/* Increased "See More" span text size responsively */}
                See More
              </span>
              <FaArrowRightLong
                className="text-white text-base md:text-lg transition-transform duration-500 ease-in-out transform group-hover:translate-x-4" // Increased arrow size on medium screens and up
                style={{
                  transition: "transform 0.5s ease-in-out", // Ensure the animation for translation is smooth
                }}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    </Link>
  );
}

export default ProjectCards;